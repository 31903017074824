import ReactDOM from 'react-dom/client';
import './index.css';
import { AppLoader } from "./components/loader/AppLoader";
// import App from './App';
import React, { Suspense } from "react";  
const App = React.lazy(() => import("./App"));
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Suspense fallback={<AppLoader />}>
      <App />
    </Suspense>
  </React.StrictMode>
);
